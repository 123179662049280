@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7jffso")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7jffso")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7jffso##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Group .path1 {
  &:before {
    content: $icon-Group-path1;
    color: rgb(76, 112, 132);
  }
}
.icon-Group .path2 {
  &:before {
    content: $icon-Group-path2;
    margin-left: -0.78125em;
    color: rgb(117, 177, 210);
  }
}
.icon-burger {
  &:before {
    content: $icon-burger;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-register {
  &:before {
    content: $icon-register;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-check-outline {
  &:before {
    content: $icon-check-outline;
  }
}
.icon-employee {
  &:before {
    content: $icon-employee;
  }
}
.icon-exit-full-screen {
  &:before {
    content: $icon-exit-full-screen;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}
.icon-organization {
  &:before {
    content: $icon-organization;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
