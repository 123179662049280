@import "../variables";

.pagination {
  display: none;

  @media (min-width: $breakpoint-xl) {
    display: block;
    position: absolute;
    right: 50%;
    margin-right: -644px;
    top: 200px;
  }

  li {
    @media (min-width: $breakpoint-xl) {
      margin-bottom: 25px;
    }
  }

  a {
    @media (min-width: $breakpoint-xl) {
      font-size: 12px;
      line-height: 25px;
      color: rgba($black, 0.25);
      text-transform: uppercase;
      position: relative;
    }

    &:before {
      @media (min-width: $breakpoint-xl) {
        content: "";
        display: inline-block;
        width: 44px;
        height: 1px;
        background-color: $primary;
        position: absolute;
        transform: rotate(-45deg);
        left: 5px;
        top: 100%;
        opacity: 0;
      }
    }

    &:hover,
    &.active {
      @media (min-width: $breakpoint-xl) {
        color: $primary;
        font-size: 18px;
        font-weight: 800;
      }
    }

    &.active {
      &:before {
        @media (min-width: $breakpoint-xl) {
          opacity: 1;
        }
      }
    }
  }
}
