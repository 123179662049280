@import "../variables";

.form-control {
  width: 100%;
  border: 0.2px solid rgba($gray-2, 0.15);
  background-color: rgba($gray-2, 0.15);
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  line-height: 30px;
  padding: 5px 16px;

  &-md {
    padding: 5px 20px;
    line-height: 40px;
  }

  &::placeholder {
    color: $gray;
  }

  &:focus {
    border-color: $secondary;
  }

  &.error {
    border-color: #c90000;
  }

  &.darken {
    background-color: #0a3454;
    border-color: #8e8e8e;
    color: $white;

    &::placeholder {
      color: $white;
    }

    &:focus {
      border-color: $primary;
    }
  }
}

.input-box {
  &.error {
    .form-control {
      border-color: #c90000;
    }
  }
}
