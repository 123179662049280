@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/open-sans/OpenSans-SemiBold.eot");
  src: url("../../assets/fonts/open-sans/OpenSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/open-sans/OpenSans-SemiBold.woff") format("woff"),
    url("../../assets/fonts/open-sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/open-sans/OpenSans-Regular.eot");
  src: url("../../assets/fonts/open-sans/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/open-sans/OpenSans-Regular.woff") format("woff"),
    url("../../assets/fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/open-sans/OpenSans-ExtraBold.eot");
  src: url("../../assets/fonts/open-sans/OpenSans-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/open-sans/OpenSans-ExtraBold.woff") format("woff"),
    url("../../assets/fonts/open-sans/OpenSans-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/open-sans/OpenSans-Bold.eot");
  src: url("../../assets/fonts/open-sans/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/open-sans/OpenSans-Bold.woff") format("woff"),
    url("../../assets/fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
