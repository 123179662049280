@import "../variables";

.btn {
  display: inline-block;
  font-weight: 700;
  color: $white;
  text-align: center;
  min-width: 114px;
  padding: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    transition: all 0.3s ease;
  }

  &-xs {
    font-size: 14px;
    line-height: 26px;
  }

  &-md {
    font-size: 18px;
    line-height: 38px;
  }

  &-lg {
    font-size: 18px;
    line-height: 44px;
  }

  &.btn-primary {
    background-color: $primary;

    &:hover {
      background-color: #519ec8;
    }
  }

  &.btn-secondary {
    background-color: $secondary;

    &:hover {
      background-color: #001d33;
      box-shadow: 0px 4px 4px rgba($black, 0.25);
    }
  }
}
