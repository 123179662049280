@import "../variables";

.hero-section {
  position: relative;
  padding: 60px 0 40px;

  @media (min-width: $breakpoint-md) {
    height: calc(100vh - 80px);
    min-height: 880px;
    padding: 188px 0 40px;
    background-repeat: no-repeat;
    background-image: url("../../../src/assets/images/hero-bg.svg");
    background-size: 320px;
    background-position: right bottom;
  }

  @media (min-width: $breakpoint-lg) {
    background-size: 366px;
    background-position: 90% bottom;
  }

  @media (min-width: $breakpoint-xxl) {
    background-position: 78% bottom;
  }

  .title-box {
    text-align: center;
    position: relative;
    line-height: normal;

    @media (min-width: $breakpoint-sm) {
      text-align: left;
    }
  }

  .main-line {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 50px;

    @media (min-width: $breakpoint-sm) {
      margin-left: -230px;
    }

    @media (min-width: $breakpoint-md) {
      margin-left: -360px;
      height: 175px;
    }

    @media (min-width: $breakpoint-lg) {
      margin-left: -430px;
    }
  }

  .line-1 {
    left: 0;
    right: 50%;
    margin-right: 282px;
    top: 882px;

    &:after {
      display: none;
    }
  }

  .line-2 {
    left: 50%;
    margin-left: -282px;
    height: 44px;
    top: 882px;
  }
}

.hero-title {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 280px;
  text-transform: uppercase;

  @media (min-width: $breakpoint-sm) {
    font-size: 38px;
    line-height: 48px;
    max-width: 854px;
    text-align: left;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 48px;
    line-height: 60px;
    margin: 0 0 40px;
  }

  &--accent {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: $primary;
    font-size: 24px;
    line-height: 18px;
    font-weight: 800;
    padding-top: 5px;

    @media (min-width: $breakpoint-sm) {
      padding-top: 10px;
      justify-content: flex-start;
      line-height: 52px;
      font-size: 64px;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 72px;
    }

    i {
      margin-right: -3px;

      @media (min-width: $breakpoint-sm) {
        font-size: 80px;
        margin-right: -6px;
      }
    }
  }
}

.hero-list {
  margin-bottom: 40px;

  li {
    color: $gray;
    font-size: 11px;
    line-height: 14px;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      line-height: 18px;
      text-align: left;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 25px;
    }

    &:before {
      @media (min-width: $breakpoint-sm) {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $secondary;
        margin: 7px 5px 0 0;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 11px;
      }
    }
  }
}

.hero-link-box {
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    text-align: left;
  }
}

.hero-link {
  text-transform: uppercase;
  color: $secondary;
  font-weight: 600;
  font-size: 14px;
  position: relative;

  @media (min-width: $breakpoint-md) {
    font-size: 18px;
  }

  &:hover {
    color: $primary;
  }

  &:after {
    content: "";
    display: block;
    width: 94px;
    height: 136px;
    position: absolute;
    bottom: -40px;
    right: -60px;
    background-image: url("../../../src/assets/images/hero-bg-mobile.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }

  i {
    padding-left: 5px;
    font-size: 12px;

    @media (min-width: $breakpoint-md) {
      font-size: 14px;
    }
  }
}
