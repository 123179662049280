@import "../variables";

.price-section {
  background-color: $gray-1;
  position: relative;
  padding: 60px 0 35px;

  @media (min-width: $breakpoint-sm) {
    padding: 80px 0;
  }

  @media (min-width: $breakpoint-md) {
    padding: 140px 0;
  }

  .container {
    position: relative;
  }

  .line-1 {
    top: 58px;
    left: 0;
    right: 50%;
    margin-right: 304px;

    @media (min-width: $breakpoint-lg) {
      margin-right: 384px;
    }

    &::after {
      display: none;
    }
  }

  .line-2 {
    left: 50%;
    height: 44px;
    top: 58px;
    margin-left: -304px;

    @media (min-width: $breakpoint-lg) {
      margin-left: -384px;
    }
  }

  .line-3 {
    left: 50%;
    transform: translateX(-50%);
    height: 256px;
    top: 100%;
  }

  .heading {
    @media (min-width: $breakpoint-sm) {
      margin-bottom: 60px;
    }

    &-accent {
      white-space: nowrap;
    }
  }
}

.price-row {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px;
  }
}

.price-col {
  margin-bottom: 20px;

  @media (min-width: $breakpoint-sm) {
    width: 50%;
    padding: 0 15px;
    display: flex;
  }

  @media (min-width: $breakpoint-md) {
    margin: 0;
    width: 33.33%;
  }
}

.price-card {
  max-width: 345px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: -5px -5px 20px $white, 5px 5px 20px rgba(174, 174, 192, 0.5);
  border-radius: 4px;
  background-color: $white;
  padding: 40px 15px;

  @media (min-width: $breakpoint-md) {
    max-width: none;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__tag {
    color: $gray;
    margin-bottom: 5px;
    padding-left: 30px;

    @media (min-width: $breakpoint-sm) {
      padding-left: 45px;
    }
  }

  &__title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
    padding-left: 30px;

    @media (min-width: $breakpoint-sm) {
      padding-left: 45px;
    }
  }

  &__list {
    margin-bottom: 60px;
    padding-left: 30px;

    @media (min-width: $breakpoint-sm) {
      padding-left: 45px;
    }

    li {
      margin-bottom: 8px;
      display: flex;
    }

    i {
      width: 18px;
      height: 18px;
      font-size: 18px;
      flex-shrink: 0;
    }

    span {
      flex-shrink: 1 1 0;
      padding-left: 10px;
      color: $gray;
    }
  }

  &__capture {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 -5px;

    @media (min-width: $breakpoint-sm) {
      margin: 0;
    }
  }

  &__btn {
    text-align: center;

    .btn {
      min-width: 200px;
      text-transform: uppercase;
    }
  }
}
