@import "../variables";

.footer {
  background-color: $secondary;
  color: $white;
  font-size: 14px;
  padding: 30px 0 80px;

  @media (min-width: $breakpoint-sm) {
    padding: 84px 0 16px;
  }

  .logo {
    margin: 0 auto 38px;
    width: 105px;

    @media (min-width: $breakpoint-sm) {
      margin: 0 0 38px;
      width: 125px;
    }
  }
}

.footer-row {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
}

.footer-subrow {
  @media (min-width: $breakpoint-sm) {
    margin: 0 -15px;
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
    justify-content: space-between;
  }
}

.footer-col {
  @media (min-width: $breakpoint-sm) {
    padding: 0 15px;
  }

  &.fg {
    flex-grow: 0.2;
  }
}

.footer-box {
  @media (min-width: $breakpoint-sm) {
    max-width: 200px;
    margin-bottom: 30px;
  }

  a {
    color: $white;

    &:hover {
      font-weight: 700;
      color: $primary;
    }
  }
}

.footer-list {
  display: none;

  @media (min-width: $breakpoint-sm) {
    line-height: 25px;
    display: block;
  }
}

.footer-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
}

.footer-text {
  display: none;

  @media (min-width: $breakpoint-sm) {
    line-height: 25px;
    display: block;
  }
}

.footer-form {
  display: none;

  @media (min-width: $breakpoint-sm) {
    min-width: 284px;
    display: block;
  }

  .footer-title {
    @media (min-width: $breakpoint-sm) {
      margin-bottom: 10px;
    }
  }

  .input-box {
    @media (min-width: $breakpoint-sm) {
      margin-bottom: 44px;
    }
  }

  .btn {
    @media (min-width: $breakpoint-sm) {
      min-width: 222px;
    }
  }
}

.footer-socials {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  @media (min-width: $breakpoint-sm) {
    border-top: 1px solid $white;
  }

  > li {
    padding: 0 20px;
  }

  a {
    font-size: 24px;
    color: $white;

    &:hover {
      color: $primary;
    }
  }
}
