$font: "Open Sans", sans-serif;

// Color:
$primary: #75b1d2;
$secondary: #052944;

$black: #000000;
$gray: #677278;
$gray-1: #fbfbfb;
$gray-2: #c4c4c4;

$white: #ffffff;

// Breakpoints
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1366px;
$breakpoint-xxl: 1600px;
