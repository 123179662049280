@import "../variables";

.how-section {
  position: relative;
  padding: 40px 0 60px;

  @media (min-width: $breakpoint-md) {
    padding: 80px 0 140px;
  }

  .heading {
    display: none;

    @media (min-width: $breakpoint-sm) {
      display: block;
      max-width: 860px;
      margin-bottom: 25px;
    }
  }

  .subtitle {
    margin-bottom: 40px;
    color: $gray;
    text-align: center;
    font-size: 10px;
    line-height: 14px;

    @media (min-width: $breakpoint-sm) {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
    }
  }
}

.how-card {
  max-width: 280px;
  margin: 0 auto 60px;

  @media (min-width: $breakpoint-sm) {
    display: flex;
    max-width: 576px;
    margin: 0 0 40px;
  }

  &:last-child {
    margin-bottom: 0;

    .how-card__text {
      @media (min-width: $breakpoint-sm) {
        max-width: 350px;
      }
    }
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.5);
    color: $primary;
    font-size: 45px;
    flex-shrink: 0;
    margin: 0 auto 40px;

    @media (min-width: $breakpoint-sm) {
      margin: 0;
    }
  }

  &__box {
    text-align: center;

    @media (min-width: $breakpoint-sm) {
      text-align: left;
      flex: 1 1 0;
      padding-left: 32px;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;

    @media (min-width: $breakpoint-sm) {
      font-size: 18px;
      line-height: 25px;
      max-width: 250px;
    }
  }

  &__text {
    color: $gray;
  }
}
